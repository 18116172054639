<template>
  <div class="bodyBg">
    <en-my-header></en-my-header>
    <div class="w1200 pt40 pb40 main">
      <div class="flex">
        <div class="get-number">
          <img src="@/assets/img/img1.png" class="img1">
          <div class="lisner plr25">
            <div class="f24 pt35 cfff">Number of queries remaining today：</div>
            <div class="numbers">
              <div class="num cfff mt25" v-if="token">{{counts}}</div>
              <template v-else>
                <div class="f18 pb20 cfff mt25">Check the remaining times after logging in</div>
                <div class="f14 to-login" @click="getLogin">Log in</div>
              </template>
            </div>
            <div class="dashed"></div>
            <div class="flex row-between mt20">
              <div class="f14 cfff flex-1">More queries are available<br>Obtaining from the Mini Terminal</div>
              <div class="code"><img src="@/assets/img/code.jpg"></div>
            </div>
          </div>
        </div>
        <div class="list ml20 flex-1">
          <div class="flex row-between">
            <div class="item cup" @click="$router.push('/en/problem')">
              <description title="Stomach health risk assessment" des="Tips:<br>Please fill in the questionnaire carefully, this screening system is not used as a basis for diagnosis."></description>
            </div>
            <div class="item cup" @click="$router.push('/en/teach')">
              <description title="Stomach health assessment" des="Tips:<br>1. This assessment needs to prepare a tongue picture in advance. <br>2. Please fill in the questionnaire carefully, this screening system is not used as a basis for diagnosis."></description>
            </div>
          </div>
          <div class="mt20 cup" @click="$router.push('/en/choose')">
            <description title="Gastroscopy recommendation evaluation" des="Tips:<br>This questionnaire will comprehensively analyze the results of stomach health risk assessment and stomach health TCM assessment. If you have not filled in the gastric health risk assessment and the gastric health TCM assessment, please fill out the post-assessment."></description>
          </div>
        </div>
      </div>
      <div class="mt20 box plr25 pb20">
        <div class="tap pt30 f18">
          <span>Query history</span>
        </div>
        <div class="content">
          <div class="cate-list pt25 flex flex-wrap" v-if="token">
            <div class="item plr20 ptb20" v-for="(item, index) in list" :key="index" @click="goHis(item)">
              <div class="title f16 c333"><img src="@/assets/img/img2.png">{{item.type == 1 ? 'Stomach health risk assessment' : item.type == 2 ? 'Stomach health assessment' : 'Gastroscopy recommendation evaluation'}}</div>
              <div class="time f14 c666 pt10">Evaluation time：{{item.create_time}}</div>
            </div>
          </div>
          <div class="noKey" v-else>
            <div class="imgKey"><img src="@/assets/img/key.png"></div>
            <div class="f18 c333 pt30">You can view after login</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { setItem } from '@/assets/js/storage'
export default {
  name: 'home',
  data(){
    return{
      counts: '',
      list: [],
      show: false
    }
  },
  computed: {
    token(){
      return this.$store.state.token;
    }
  },
  async mounted() {
    this.goNumber();
    this.getList();
  },
  methods:{
    async goNumber(){
      var params = {
        token: this.token
      }
      var res = await this.$api.post('/index/getcounts', params);
      this.counts = res.counts;
    },
    async getList(){
      var params = {
        token: this.token,
        limit: 12,
        page: 1,
        type: 0
      }
      var res = await this.$api.post('/index/historicalrecords', params);
      this.list = res.data;
      if(res.data.length == 0) this.show = true;
    },
    async getLogin(){
      this.$router.push('/en/login');
    },
    async goHis(item){
      var params = {
        token: this.token,
        id: item.id
      }
      var res = await this.$api.post('/index/historicalrecordsdetail', params);
      var data = res.data.diagnosis;
      if(item.type == 1){
        if(data == 1){
          this.$router.push('/en/result?isCheck=true&img=false&id='+item.id);
        }else{
          this.$router.push('/en/result?isCheck=false&img=true&id='+item.id);
        }
      } else if(item.type == 2){
        setItem('teach', data);
        var type = 0;
        if(data.wt === '0' && data.zzfour === '0' && data.zznine === '0' && data.zzten === '0'){
          type = 0;
        }else{
          type = 1;
        }
        this.$router.push('/en/teach-result?type=' + type);
      }else{
        this.$router.push('/en/result?isCheck='+data+'&id='+item.id+'&img=true');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .cup{
      cursor: pointer;
    }
  .main{
    box-sizing: border-box;
    min-height: calc(100vh - 80px);
    .box{
      background-color: #FFFFFF;
      
      .content{
        width: 100%;
        height: 352px;
        position: relative;
        .cate-list{
          .item{
            width: 276px;
            // height: 84px;
            background: #F5F6F7;
            border-radius: 10px;
            margin-right: 15px;
            margin-bottom: 18px;
            cursor: pointer;
            &:nth-of-type(4n){
              margin-right: 0;
            }
            .title{
              img{
                margin-right: 8px;
              }
            }
          }
        }
        .noKey{
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          .imgKey{
            width: 160px;
            height: 160px;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .tap{
        width: 100%;
        height: 66px;
        box-sizing: border-box;
        border-bottom: 1px solid #EBEBEB;
        span{
          border-bottom: 3px solid #333333;
          padding-bottom: 8px;
        }
      }
    }
    .list{
      .item{
        width: 49%;
      }
    }
    .get-number{
      position: relative;
      width: 290px;
      height: 340px;
      // line-height: 1;
      .lisner{
        position: relative;
        z-index: 2;
        .code{
          width: 100px;
          height: 100px;
          padding: 4px;
          box-sizing: border-box;
          background-color: #FFFFFF;
          border-radius: 50%;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
          }
        }
        .dashed{
          border-top: 1px dashed #FFFFFF;
        }
        .numbers{
          height: 80px;
          .num{
            font-size: 84px;
            line-height: 52px;
          }
        }
        .to-login{
          color: #857BFE;
          background-color: #FFFFFF;
          width: 80px;
          height: 32px;
          line-height: 32px;
          border-radius: 32px;
          text-align: center;
          cursor: pointer;
        }
      }
      .img1{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 290px;
        height: 340px;
      }
    }
  }
</style>
